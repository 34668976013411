<template>
	<div>
		<div class="tw-flex tw-items-center tw-space-s-7">
			<v-btn
				icon
				class="tw-bg-72 tw-text-white"
				@click="$goBack('/products?tab=offers')"
			>
				<v-icon>mdi-chevron-left</v-icon>
			</v-btn>
			<span class="tw-text-xl">{{ $t('offer-details') }}</span>
		</div>
		<div v-if="$offer && $offer.id" class="tw-mb-16 tw-mt-6">
			<v-row>
				<v-col cols="12" md="6" class="tw-flex tw-flex-col lg:tw-pe-24">
					<h1
						class="tw-font-bold tw-text-2xl 3xl:tw-text-6xl tw-mb-3"
					></h1>
					<span class="tw-text-xl 3xl:tw-text-3xl">
						{{ $offer.description }}
					</span>
					<div
						class="md:tw-mt-16 md:tw-mb-24 tw-my-6 tw-py-4 tw-border-t tw-border-b tw-border-E5 tw-flex-1"
					>
						<v-row>
							<v-col cols="3">
								<div>
									<span class="tw-text-13px tw-font-light">{{
										$t('seller')
									}}</span>
									<h3
										v-if="
											$offer.supplier &&
											$offer.supplier.representativeName
										"
										class="tw-font-semibold tw-text-base tw-mt-2 tw-mb-3"
									>
										{{ $offer.supplier.representativeName }}
									</h3>
									<span class="tw-text-13px tw-font-light">{{
										$t('availability')
									}}</span>
									<h3
										class="tw-font-medium tw-text-base tw-mt-2.5 tw-text-black tw-font-Poppins-text"
									>
										{{ $offer.status ? 'In store' : 'Out of stock' }}
									</h3>
								</div>
							</v-col>
							<v-col cols="3">
								<div>
									<span class="tw-text-13px tw-font-light">{{
										$t('pieces')
									}}</span>
									<h3
										class="tw-font-semibold tw-text-base tw-mt-2 tw-mb-3"
									>
										{{
											$offer.isSpecifiedQuantity ? 'One Piece' : '-'
										}}
									</h3>
									<span class="tw-text-13px tw-font-light">{{
										$t('sizes')
									}}</span>
									<h3
										class="tw-font-medium tw-text-base tw-mt-2.5 tw-text-black tw-font-Poppins-text"
									>
										<div class="tw-flex tw-items-center">
											<span>{{ getSizes($offer.sizes) }}</span>
										</div>
									</h3>
								</div>
							</v-col>

							<v-col cols="3">
								<div>
									<span class="tw-text-13px tw-font-light">{{
										$t('size')
									}}</span>
									<h3
										class="tw-font-semibold tw-text-base tw-mt-2 tw-mb-3"
									>
										{{ $t('small') }}
									</h3>
									<span class="tw-text-13px tw-font-light">{{
										$t('category')
									}}</span>
									<h3
										class="tw-font-medium tw-text-base tw-mt-2.5 tw-text-black tw-font-Poppins-text"
										v-if="
											$offer.mainCategory &&
											$offer.mainCategory.length
										"
									>
										{{ $offer.mainCategory[0].name }}
									</h3>
								</div>
							</v-col>

							<v-col cols="3">
								<div>
									<span class="tw-text-13px tw-font-light">{{
										$t('headers.color')
									}}</span>
									<h3
										class="tw-font-semibold tw-text-base tw-mt-2 tw-mb-3"
									>
										{{ $t('black') }}
									</h3>
									<span class="tw-text-13px tw-font-light">{{
										$t('subCategory')
									}}</span>
									<h3
										class="tw-font-medium tw-text-base tw-mt-2.5 tw-text-black tw-font-Poppins-text"
										v-if="
											$offer.subCategory && $offer.subCategory.length
										"
									>
										{{ $offer.subCategory[0].name }}
									</h3>
								</div>
							</v-col>
						</v-row>
					</div>

					<div
						v-if="oldPrice"
						class="tw-me-3 tw-text-xl tw-font-medium tw-font-Rubik-text tw-opacity-50 tw-line-through"
					>
						SAR {{ oldPrice }}
					</div>
					<div
						class="tw-flex tw-items-center tw-justify-between tw-flex-none"
					>
						<div class="tw-flex tw-items-end">
							<h2
								v-if="$offer && $offer.products"
								class="tw-text-3xl xl:tw-text-4xl tw-font-medium tw-font-Rubik-text"
							>
								{{ $offer.finalPrice }}
							</h2>
							<span class="tw-ms-3">{{ $offer.currency.currency }}</span>
						</div>
						<div
							class="tw-rounded-md tw-space-s-4 tw-flex tw-items-center"
						>
							<base-quantity
								v-model="quantity"
								:large="!dense"
								:max="maxQuantity"
								:icon-ratio="2.8"
								:size="dense ? 48 : 56"
								:class="[dense ? 'tw-bg-gray-50' : '']"
								@change="onChangeQuantity"
							/>
							<v-btn
								tile
								dark
								depressed
								color="#F5CA15"
								style="background-color: #f5ca15 !important"
								class="tw-rounded-md tw-p-0 tw-m-0 tw-h-8 tw-min-w-10"
								:class="[
									dense ? 'tw-w-14 tw-h-12' : 'xl:tw-h-14 xl:tw-w-16',
								]"
								:loading="isUpdating"
								@click="onAddToCart($offer, 'offer')"
							>
								<icon-cart-white :size="dense ? 20 : 24" />
							</v-btn>
						</div>
					</div>
				</v-col>
				<v-col class="lg:tw-ps-24" cols="12" md="6">
					<div>
						<v-img
							cover
							ref="imgRef"
							:height="dense ? 308 : 420"
							:src="
								$image(
									$get(
										$offer,
										`attachments.${selectedImage}.attachedLink`
									)
								)
							"
							class="tw-rounded-10px tw-w-full tw-bg-gray-100"
							@load="calculateImgWidth"
							@error="$onImgError({ ref: $refs.imgRef })"
						/>
					</div>

					<base-slideable
						:value="$offer.attachments.length"
						show-arrows-on-hover
						class="tw-mt-3.5"
					>
						<div class="tw-flex tw-mt-3.5 tw-space-s-4">
							<div v-for="(image, i) in $offer.attachments" :key="i">
								<v-img
									:width="imgWidth / 3 - 8"
									:src="$image(image.attachedLink)"
									:class="{
										'tw-border-F2 tw-border-2 tw-bg-F2':
											i === selectedImage,
									}"
									alt
									cover
									ref="sImgRef"
									class="tw-h-32 tw-object-cover tw-object-center tw-rounded-10px tw-border tw-backdrop-opacity-20"
									@click="selectedImage = i"
									@error="
										$onImgError({ ref: $refs.sImgRef, index: i })
									"
								/>
							</div>
						</div>
					</base-slideable>
				</v-col>
				<div class="tw-w-full tw-mt-10" v-if="$offer.products">
					<h2 class="tw-text-19px tw-font-semibold">
						{{ $t('products-for-this-offer') }}
					</h2>
					<v-row class="mb-3 tw-mt-9 lg:tw-mb-8">
						<v-col
							v-for="(item, i) in $offer.products"
							:key="i"
							cols="12"
							sm="6"
							md="4"
							lg="3"
							xl="2"
						>
							<ProductCard
								:product-info="item"
								:show-actions="false"
								@onClick="$router.push(`/product/${item.id}`)"
							/>
						</v-col>
					</v-row>
				</div>
			</v-row>
		</div>
	</div>
</template>

<script>
import { truncate } from 'lodash'
import { mapActions, mapGetters } from 'vuex'

import BaseQuantity from '../components/base/BaseQuantity.vue'
import ProductCard from '../components/customs/ProductCard.vue'

export default {
	name: 'offers',
	components: {
		ProductCard,
		BaseQuantity,
	},
	props: {
		dense: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			property: 'value',
			loaded: false,
			selectedImage: 0,
			quantity: 1,
			isUpdating: false,
			imgWidth: 477,
		}
	},
	computed: {
		...mapGetters('cart', ['$cart']),
		...mapGetters('auth', ['$currentUser']),
		...mapGetters('offers', ['$offers', '$offer']),
		maxQuantity() {
			return Infinity
		},
		isInCart() {
			return !!this.cartItem
		},
		cartItem() {
			if (!this.$offer) return
			return this.$cart.offers.data.find((cartItem) => {
				return cartItem.cartableId == this.$offer.id
			})
		},
		oldPrice() {
			if (!this.$offer) return 0
			if (this.$offer.offeringType === 'amount') {
				return +this.$offer.finalPrice + +this.$offer.discount
			}
			return this.$offer.products.reduce((total, pro) => {
				return +total + +pro.price
			}, 0)
		},
	},
	watch: {
		cartItem: {
			deep: true,
			immediate: true,
			handler(cartItem) {
				if (!cartItem) return (this.quantity = 1)
				if (cartItem.quantity != this.quantity && !this.isUpdating) {
					this.quantity = cartItem.quantity
				}
			},
		},
	},
	methods: {
		...mapActions('cart', ['addToCart', 'updateCartItem']),
		...mapActions('products', ['getProducts', 'getProductsDependency']),
		...mapActions('offers', ['getRunningOffers', 'getOffer']),
		...mapActions('suppliers', ['getSupplier']),
		...mapActions('category', ['getCategories', 'getCategoriesForProduct']),
		...mapActions('offers', [
			'getOffers',
			'deleteOffer',
			'updateOffer',
			'getMarketingOffers',
			'deleteMarketingOffer',
			'updateMarketingOffer',
			'createOffer',
			'countBuyer',
		]),
		calculateImgWidth() {
			if (!this.$refs.imgRef) return
			this.imgWidth =
				this.$refs.imgRef.$el.getBoundingClientRect().width || 477
		},
		async onAddToCart(offer, type = 'offer') {
			this.isUpdating = true
			await this.addToCart({
				userId: this.$currentUser.id,
				cartableId: offer.id,
				cartableType: type,
				quantity: 1,
			})
			this.isUpdating = false
			this.$toast.success(this.$t('offer-added-to-cart'))
		},
		async onChangeQuantity(payload) {
			if (!this.isInCart) return this.onAddToCart(this.$offer, 'offer')

			this.isUpdating = true
			await this.updateCartItem({
				...this.cartItem,
				quantity: payload,
			})
			this.isUpdating = false
		},
		formatPrice(price) {
			console.log(price)
			return price
			// return numberWithCommas(+price);
		},
		getSizes(sizes) {
			console.log(sizes, 'sizessizessizessizessizessizes')
			// return sizes.length ? sizes.map(a => a.name[0]).join("-") : "-";
			return 1
		},
		formatProductName(name) {
			return truncate(name, {
				length: 34,
				separator: '...',
			})
		},
	},
	created() {
		// console.log(this.$route.params.id)
		this.$route.params.id === 'all'
			? this.getRunningOffers()
			: this.getOffer({ id: this.$route.params.id })
		// this.loaded = true
		this.getCategories({ type: 'offer' })

		// this.getProducts({ 	perPage: 250 })
		// this.getProductsDependency({ type: 'offer' })
		// this.getProductsDependency({ type: 'buyer' })

		// this.getCategoriesForProduct({ type: 'product' })

		if (this.$currentUser.isSupplier) {
			this.supplierId = this.$currentUser.supplier[0].id
		}
		// supplier
		else this.getSupplier()

		// this.isLoaded = true
		// this.countBuyer()
	},
}
</script>
