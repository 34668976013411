var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"tw-flex tw-items-center tw-space-s-7"},[_c('v-btn',{staticClass:"tw-bg-72 tw-text-white",attrs:{"icon":""},on:{"click":function($event){return _vm.$goBack('/products?tab=offers')}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('span',{staticClass:"tw-text-xl"},[_vm._v(_vm._s(_vm.$t('offer-details')))])],1),(_vm.$offer && _vm.$offer.id)?_c('div',{staticClass:"tw-mb-16 tw-mt-6"},[_c('v-row',[_c('v-col',{staticClass:"tw-flex tw-flex-col lg:tw-pe-24",attrs:{"cols":"12","md":"6"}},[_c('h1',{staticClass:"tw-font-bold tw-text-2xl 3xl:tw-text-6xl tw-mb-3"}),_c('span',{staticClass:"tw-text-xl 3xl:tw-text-3xl"},[_vm._v(" "+_vm._s(_vm.$offer.description)+" ")]),_c('div',{staticClass:"md:tw-mt-16 md:tw-mb-24 tw-my-6 tw-py-4 tw-border-t tw-border-b tw-border-E5 tw-flex-1"},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('div',[_c('span',{staticClass:"tw-text-13px tw-font-light"},[_vm._v(_vm._s(_vm.$t('seller')))]),(
										_vm.$offer.supplier &&
										_vm.$offer.supplier.representativeName
									)?_c('h3',{staticClass:"tw-font-semibold tw-text-base tw-mt-2 tw-mb-3"},[_vm._v(" "+_vm._s(_vm.$offer.supplier.representativeName)+" ")]):_vm._e(),_c('span',{staticClass:"tw-text-13px tw-font-light"},[_vm._v(_vm._s(_vm.$t('availability')))]),_c('h3',{staticClass:"tw-font-medium tw-text-base tw-mt-2.5 tw-text-black tw-font-Poppins-text"},[_vm._v(" "+_vm._s(_vm.$offer.status ? 'In store' : 'Out of stock')+" ")])])]),_c('v-col',{attrs:{"cols":"3"}},[_c('div',[_c('span',{staticClass:"tw-text-13px tw-font-light"},[_vm._v(_vm._s(_vm.$t('pieces')))]),_c('h3',{staticClass:"tw-font-semibold tw-text-base tw-mt-2 tw-mb-3"},[_vm._v(" "+_vm._s(_vm.$offer.isSpecifiedQuantity ? 'One Piece' : '-')+" ")]),_c('span',{staticClass:"tw-text-13px tw-font-light"},[_vm._v(_vm._s(_vm.$t('sizes')))]),_c('h3',{staticClass:"tw-font-medium tw-text-base tw-mt-2.5 tw-text-black tw-font-Poppins-text"},[_c('div',{staticClass:"tw-flex tw-items-center"},[_c('span',[_vm._v(_vm._s(_vm.getSizes(_vm.$offer.sizes)))])])])])]),_c('v-col',{attrs:{"cols":"3"}},[_c('div',[_c('span',{staticClass:"tw-text-13px tw-font-light"},[_vm._v(_vm._s(_vm.$t('size')))]),_c('h3',{staticClass:"tw-font-semibold tw-text-base tw-mt-2 tw-mb-3"},[_vm._v(" "+_vm._s(_vm.$t('small'))+" ")]),_c('span',{staticClass:"tw-text-13px tw-font-light"},[_vm._v(_vm._s(_vm.$t('category')))]),(
										_vm.$offer.mainCategory &&
										_vm.$offer.mainCategory.length
									)?_c('h3',{staticClass:"tw-font-medium tw-text-base tw-mt-2.5 tw-text-black tw-font-Poppins-text"},[_vm._v(" "+_vm._s(_vm.$offer.mainCategory[0].name)+" ")]):_vm._e()])]),_c('v-col',{attrs:{"cols":"3"}},[_c('div',[_c('span',{staticClass:"tw-text-13px tw-font-light"},[_vm._v(_vm._s(_vm.$t('headers.color')))]),_c('h3',{staticClass:"tw-font-semibold tw-text-base tw-mt-2 tw-mb-3"},[_vm._v(" "+_vm._s(_vm.$t('black'))+" ")]),_c('span',{staticClass:"tw-text-13px tw-font-light"},[_vm._v(_vm._s(_vm.$t('subCategory')))]),(
										_vm.$offer.subCategory && _vm.$offer.subCategory.length
									)?_c('h3',{staticClass:"tw-font-medium tw-text-base tw-mt-2.5 tw-text-black tw-font-Poppins-text"},[_vm._v(" "+_vm._s(_vm.$offer.subCategory[0].name)+" ")]):_vm._e()])])],1)],1),(_vm.oldPrice)?_c('div',{staticClass:"tw-me-3 tw-text-xl tw-font-medium tw-font-Rubik-text tw-opacity-50 tw-line-through"},[_vm._v(" SAR "+_vm._s(_vm.oldPrice)+" ")]):_vm._e(),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-between tw-flex-none"},[_c('div',{staticClass:"tw-flex tw-items-end"},[(_vm.$offer && _vm.$offer.products)?_c('h2',{staticClass:"tw-text-3xl xl:tw-text-4xl tw-font-medium tw-font-Rubik-text"},[_vm._v(" "+_vm._s(_vm.$offer.finalPrice)+" ")]):_vm._e(),_c('span',{staticClass:"tw-ms-3"},[_vm._v(_vm._s(_vm.$offer.currency.currency))])]),_c('div',{staticClass:"tw-rounded-md tw-space-s-4 tw-flex tw-items-center"},[_c('base-quantity',{class:[_vm.dense ? 'tw-bg-gray-50' : ''],attrs:{"large":!_vm.dense,"max":_vm.maxQuantity,"icon-ratio":2.8,"size":_vm.dense ? 48 : 56},on:{"change":_vm.onChangeQuantity},model:{value:(_vm.quantity),callback:function ($$v) {_vm.quantity=$$v},expression:"quantity"}}),_c('v-btn',{staticClass:"tw-rounded-md tw-p-0 tw-m-0 tw-h-8 tw-min-w-10",class:[
								_vm.dense ? 'tw-w-14 tw-h-12' : 'xl:tw-h-14 xl:tw-w-16' ],staticStyle:{"background-color":"#f5ca15 !important"},attrs:{"tile":"","dark":"","depressed":"","color":"#F5CA15","loading":_vm.isUpdating},on:{"click":function($event){return _vm.onAddToCart(_vm.$offer, 'offer')}}},[_c('icon-cart-white',{attrs:{"size":_vm.dense ? 20 : 24}})],1)],1)])]),_c('v-col',{staticClass:"lg:tw-ps-24",attrs:{"cols":"12","md":"6"}},[_c('div',[_c('v-img',{ref:"imgRef",staticClass:"tw-rounded-10px tw-w-full tw-bg-gray-100",attrs:{"cover":"","height":_vm.dense ? 308 : 420,"src":_vm.$image(
								_vm.$get(
									_vm.$offer,
									("attachments." + _vm.selectedImage + ".attachedLink")
								)
							)},on:{"load":_vm.calculateImgWidth,"error":function($event){return _vm.$onImgError({ ref: _vm.$refs.imgRef })}}})],1),_c('base-slideable',{staticClass:"tw-mt-3.5",attrs:{"value":_vm.$offer.attachments.length,"show-arrows-on-hover":""}},[_c('div',{staticClass:"tw-flex tw-mt-3.5 tw-space-s-4"},_vm._l((_vm.$offer.attachments),function(image,i){return _c('div',{key:i},[_c('v-img',{ref:"sImgRef",refInFor:true,staticClass:"tw-h-32 tw-object-cover tw-object-center tw-rounded-10px tw-border tw-backdrop-opacity-20",class:{
									'tw-border-F2 tw-border-2 tw-bg-F2':
										i === _vm.selectedImage,
								},attrs:{"width":_vm.imgWidth / 3 - 8,"src":_vm.$image(image.attachedLink),"alt":"","cover":""},on:{"click":function($event){_vm.selectedImage = i},"error":function($event){return _vm.$onImgError({ ref: _vm.$refs.sImgRef, index: i })}}})],1)}),0)])],1),(_vm.$offer.products)?_c('div',{staticClass:"tw-w-full tw-mt-10"},[_c('h2',{staticClass:"tw-text-19px tw-font-semibold"},[_vm._v(" "+_vm._s(_vm.$t('products-for-this-offer'))+" ")]),_c('v-row',{staticClass:"mb-3 tw-mt-9 lg:tw-mb-8"},_vm._l((_vm.$offer.products),function(item,i){return _c('v-col',{key:i,attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"2"}},[_c('ProductCard',{attrs:{"product-info":item,"show-actions":false},on:{"onClick":function($event){return _vm.$router.push(("/product/" + (item.id)))}}})],1)}),1)],1):_vm._e()],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }